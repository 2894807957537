@import "../../style/variables";

div.searchArea {
  height: 350px;
  display: flex !important;
  align-items: center;
  padding: 30px 16px;
  box-sizing: border-box;
  background-color: $ilf-orange;

  @include media-mobile {
    height: 166px;
  }
}

.searchAreaInner {
  height: 200px;
  width: 665px;
  margin: auto;
}

.showCommunitiesText {
  text-align: left;
}

.filtersArea {
  @include media-mobile {
    display: none;
  }
}
