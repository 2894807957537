@import "../../style/variables.scss";

.languageCard {
  display: inline-block !important;
  min-width: 200px;
  margin-bottom: 48px;
  text-align: left;

  @include media-mobile {
    width: 100%;
  }
}

.languageLink {
  display: flex;
  align-items: center;
  margin-bottom: 16px;
}

.languageLinkLabel {
  border-bottom: 1px solid $ilf-blue;
}

.numResources {
  color: $ilf-grey;
  font-weight: 500;
}
