@import "../../style/variables.scss";

.searchbarWrapper {
  position: relative;
  cursor: text;
  display: block;
}

.searchBar {
  font-family: "Vaud Display", Arial, sans-serif;
  font-weight: 500;
  color: $ilf-grey;
  cursor: text;
}

div.searchIcon {
  position: absolute;
  left: 16px;
  height: 100%;
  display: flex !important;
  align-items: center;
  color: inherit;
  z-index: 1;
  pointer-events: none;
}
