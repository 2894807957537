@import "../../style/variables.scss";

.languagesList {
  color: $ilf-blue;
  background-color: $color-white;
  width: 100%;
  max-width: 1078px;
  margin: auto;
  box-sizing: border-box;
  padding: 70px 16px 48px 16px;

  @include media-mobile {
    padding-top: 48px;
  }
}

.relatedLanguages {
  margin-top: 50px;
}

.relatedLanguagesHeader {
  font-weight: 900;
  color: $ilf-grey;
  margin-bottom: 20px;
}

.languageGrid {
  display: grid;
  width: 100%;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  @include media-tablet {
    grid-template-columns: 1fr 1fr 1fr;
  }
  @include media-mobile {
    grid-template-columns: 1fr;
  }
}
