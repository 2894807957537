@import "../../style/variables.scss";

.languagesArea {
  width: 100%;
  background-color: $color-white;
}

.languageSearchArea {
  color: $color-white;
  background-color: $ilf-blue;
  padding: 64px 16px 56px 16px;
  box-sizing: border-box;

  @include media-mobile {
    height: fit-content; // Variable on mobile
    padding: 30px 16px;
  }
}

.languageSearchInner {
  width: 100%;
  max-width: 554px;
  margin: auto;
}

.languageListArea {
  position: relative;
  width: 100%;
  background-color: $color-white;
}

.curveBlueLanguageSearchBottom {
  z-index: 2;
  position: absolute;
  left: 0px;
  top: -1px;
  width: 100%;
  height: auto;

  @include media-mobile {
    display: none;
  }
}

.languagesArea p.publicCta {
  padding: 6px 16px 6px 16px;
  margin: 0px;
  text-align: center;
  font-weight: bold;

  @include media-mobile {
    text-align: left;
    font-size: 16px;
  }
}

p.publicCta a {
  color: white;
  font-weight: bold;
  text-decoration: underline;
  text-underline-offset: 2px;
  // border-bottom: 1px solid #efefef;
  // padding-bottom: 2px;

  &:hover {
    color: white;
    text-decoration: underline;
  }
}
