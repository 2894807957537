@import "../../style/variables";

.languageScrollLink {
  color: $ilf-blue;
  cursor: pointer;

  span {
    border-bottom: 1px solid $ilf-blue;
  }
}
