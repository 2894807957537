@import "../../style/variables.scss";

.searchOption {
  text-align: left;
  border-bottom: 1px solid #ccc;
  padding: 12px 48px;
  cursor: pointer;
  font-size: 18px;
}

.searchOption:hover {
  background-color: $color-light-blue;
}

.alsoKnownAs {
  font-size: 14px;
}

.alternativeNames {
  font-weight: 900;
}

.languageSearchWrapper {
  color: $ilf-blue;
  padding-bottom: 20px;
  @include media-mobile {
    padding-bottom: 0px;
  }
}

.highlight {
  color: $ilf-blue;
  font-weight: 900;
  background-color: inherit;
}
