$ilf-blue: hsl(194, 100%, 47%);
$ilf-grey: hsl(0, 0%, 33%);
$ilf-orange: hsl(22, 90%, 54%);
$ilf-lightgrey: hsl(0, 0%, 66%);
$color-black: hsl(0, 0%, 0%);
$color-white: hsl(0, 0%, 100%);
$color-light-blue: hsl(216, 100%, 94%);

@mixin media-laptop {
  @media screen and (max-width: 1500px) {
    @content;
  }
}

@mixin media-small-desktop {
  @media screen and (max-width: 1350px) {
    @content;
  }
}

@mixin media-tablet {
  @media screen and (max-width: 1150px) {
    @content;
  }
}

@mixin media-mobile {
  @media screen and (max-width: 850px) {
    @content;
  }
}
