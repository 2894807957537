@import "../../style/variables.scss";

.communityDetails {
  color: $ilf-grey;
  font-family: "Vaud Display", Arial, sans-serif;
  font-style: normal;
  font-weight: 500;
  cursor: auto;
  padding-bottom: 16px;
  padding-right: 16px;

  h5 {
    margin: 14px 0px 12px 0px;
  }

  @include media-mobile {
    padding-right: 0px;
  }
}

.communityDetailsBlock {
  padding-bottom: 18px;
  border-bottom: 1px solid #eee;
  font-size: 14px;
}

.communityDetails h4 {
  color: $ilf-grey;
  font-family: "Vaud Display", Arial, sans-serif;

  margin: 0px 0px 6px 0px;
  padding: 0px;
}

.communityName {
  font-weight: 900;
  text-align: left;
}

.alsoKnownAs {
  font-size: 14px;
  @include media-mobile {
    font-size: 16px;
  }
}

.alternativeNames {
  font-family: "Vaud Display", Arial, sans-serif;
  font-weight: 900;
}

.languagesBlock {
  color: $ilf-blue;
  @include media-mobile {
    font-size: 16px;
  }
}

.noDataMessageLink {
  border-bottom: 1px solid $ilf-lightgrey;
}

.publicDetailsBlock {
  padding-top: 24px;
  padding-bottom: 23px;
}

.communityDetailsBlock.publicDetailsBlock p {
  font-size: inherit;
  margin: 0px;
  color: $ilf-grey;
  font-size: 14px;

  @include media-mobile {
    font-size: 16px;
  }
}

.communityDetailsBlock.publicDetailsBlock a {
  color: $ilf-orange;
  font-weight: 900;
  border-bottom: 1px solid $ilf-orange;

  &:hover {
    color: $ilf-orange;
  }
}
