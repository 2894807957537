@import "../../style/variables";

.appBtn {
  font-weight: 900;
  font-family: "Vaud Display", Arial, sans-serif;
  font-size: 18px;
  font-style: normal;
  display: flex;
  align-items: center;
  padding: 14px 24px 10px 24px;
  cursor: pointer;
  display: block;
  margin: auto;
  box-sizing: border-box;
  line-height: 24px;
  border-radius: 0px;
  border-width: 2px;
  border-style: solid;

  &:focus {
    outline: 0;
  }

  @include media-mobile {
    width: 100%;
    margin-top: 24px;
  }
}

.blue.outlined {
  color: $ilf-blue;
  border-color: $ilf-blue;
  background-color: $color-white;
}

.blue.filled {
  color: $color-white;
  border-color: $ilf-blue;
  background-color: $ilf-blue;
}

.orange.outlined {
  color: $ilf-orange;
  border-color: $ilf-orange;
  background-color: $color-white;
}
