@import "../../style/variables.scss";

.noResourcesMsg {
  text-align: center;
  color: $ilf-grey;
  font-weight: 500;
  padding: 16px;

  a {
    border-bottom: 1px solid $ilf-grey;
  }
}

.chosenLanguage {
  font-weight: 900;
}
