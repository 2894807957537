@import "../../style/variables.scss";

h5.detailsListHeader {
  display: flex;
  align-items: center;
}

.detailsListHeader,
.detailsListHeader a,
.detailsListHeader a:visited {
  color: $ilf-orange;
  text-decoration: none;
}

.detailsListHeaderLabel {
  display: inline-block;
}

.listIcon {
  color: $ilf-orange;
  display: inline-block;
  margin-right: 10px;
}

.detailListTextEntry {
  display: flex;
}

.displayItemSubTitle {
  font-size: 12px;
  line-height: 16px;
  @include media-mobile {
    font-size: 14px;
  }
}

.detailsListContent {
  font-size: 14px;
  @include media-mobile {
    font-size: 16px;
  }
}

.noDataMessage {
  color: $ilf-lightgrey;
}

.detailsListLink {
  display: flex;
  align-items: center;
}

.detailsList .regularListItemLink {
  color: $ilf-grey;
  border-bottom: 1px solid $ilf-grey;
}

.detailsList .boldListItemLink {
  font-weight: 900;
  color: $ilf-blue;
  border-bottom: 1px solid $ilf-blue;

  &:hover {
    color: $ilf-blue;
    border-bottom: 1px solid $ilf-blue;
  }
}

.bookListItem div.buyBookHeader {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.bookListItem div.buyBookLinkGroup {
  color: $ilf-blue;
  display: flex;
  align-items: center;
}

.extraText {
  margin-top: 8px;
}

.buyLink {
  margin-left: 8px;
  line-height: 16px;
  border-bottom: 1px solid $ilf-blue;
  font-weight: 900;
}

.shoppingCartLink {
  display: flex;
  align-items: center;
}

.detailsListBookName {
  margin-right: 10px;
}

.detailsList p.publicBookDetails {
  font-size: 14px;
  margin-top: 14px;
  margin-bottom: 20px;
  @include media-mobile {
    font-size: 16px;
  }
}

.detailsList p.publicBookDetails a {
  color: $ilf-blue;
  border-bottom: 1px solid #00b8f0;
  font-weight: 900;
}

// The first unavailable book
// Following the commercially available books
// Gets a divider
.commercialBook + .unavailableBook {
  margin-top: 20px;
  border-top: 1px solid #eee;
}

h6.booklistSubheader {
  font-size: 14px;
  color: $ilf-orange;
  margin: 16px 0;
  @include media-mobile {
    font-size: 16px;
  }
}
