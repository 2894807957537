@import "style/variables";
@import url("https://fonts.googleapis.com/css2?family=Gaegu:wght@400;700&display=swap");

.App {
  font-family: "Vaud Display", Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-weight: 900;
  width: auto;
  font-family: "Vaud Display", Arial, sans-serif;
  font-style: normal;
  font-size: 16px;
  line-height: 24px;
  color: white;
  box-sizing: border-box;
  max-width: 100%;
}

.AppContent {
  font-size: 18px;
}

.App h1 {
  font-family: "Vaud Display", Arial, sans-serif;
  font-style: normal;
  font-weight: 900;
  font-size: 50px;
  line-height: 50px;
  margin: 0px;
  text-align: center;
  overflow: auto;
  padding-bottom: 48px;

  @include media-mobile {
    text-align: left;
    font-size: 30px;
    line-height: 40px;
    padding-bottom: 24px;
  }
}

.App h2 {
  font-family: "Vaud Display", Arial, sans-serif;
  font-style: normal;
  font-weight: 900;
  font-size: 30px;
  line-height: 40px;
  padding-bottom: 36px;
  margin: 0px;
  text-align: center;

  @include media-mobile {
    text-align: left;
    padding-bottom: 24px;
  }
}

.App h3 {
  font-family: "Vaud Display", Arial, sans-serif;
  font-style: normal;
  font-weight: 900;
  font-size: 20px;
  line-height: 27px;
}

.App h4 {
  font-family: "Vaud Display", Arial, sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 26px;
  text-align: left;
}

.App h5 {
  font-family: "Vaud Display", Arial, sans-serif;
  font-style: normal;
  font-weight: 900;
  font-size: 16px;
  line-height: 26px;
  margin: 20px 0px 12px 0px;
  text-align: left;

  @include media-mobile {
    font-size: 18px;
  }
}

.App p {
  font-family: "Vaud Display", Arial, sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 24px;
}

.App ul {
  list-style-type: none;
  padding: 0px;
  margin: 0px;
}

.App a {
  text-decoration: none;
  color: inherit;

  &:hover {
    text-decoration: none;
    color: inherit;
  }
}

.App img {
  background-color: transparent;
}

.App mark {
  padding: 0em;
}

.App strong {
  font-weight: 900;
}

.App div {
  display: block;
  box-sizing: border-box;
}

.communityDetailsWrapper {
  background-color: $color-white;
  padding: 28px 16px 0px 16px;
}
