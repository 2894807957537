@import "../../style/variables";

.communitySidebar {
  position: absolute;
  top: 45px;
  height: 79%;
  background-color: white;
  z-index: 7;
}

.communitySidebarInner {
  height: 100%;
  width: 100%;
  position: relative;
  box-sizing: border-box;
  padding: 30px 8px 0px 20px;
}

.communityDetailsWrapper {
  height: 100%;
  overflow: auto;
}

.closeButton {
  position: absolute;
  right: 12px;
  top: 12px;
  color: $ilf-grey;
  cursor: pointer;
  height: 12px;
  width: 12px;
  display: flex;
  padding: 0px;
  border: none;
  background-color: inherit;
}
