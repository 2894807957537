@import "../../style/variables";

.filterButtonArea {
  display: flex;
}

.filterButton {
  border: 2px solid white;
  width: 200px;
  height: 32px;
  font-size: 14px;
  width: fit-content;
  padding: 4px 21px 6px 21px;
  box-sizing: border-box;
  position: relative;
  font-weight: 900;
  cursor: pointer;

  &:focus {
    outline: 0;
  }
  @include media-mobile {
    font-size: 16px;
  }
}

.filterButton.active {
  background-color: white;
  color: $ilf-orange;
  font-weight: 900;
  padding: 4px 13px 6px 29px;
}

.filterButton.inactive {
  background-color: $ilf-orange;
  color: white;
  opacity: 0.8;
}

.tickWrapper {
  position: absolute;
  left: 8px;
  top: 4px;
}
