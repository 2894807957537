.mapboxgl-ctrl-group button {
  width: 100% !important;
}

/* Move mapbox icons around, override default locations  */
.mapboxgl-ctrl.mapboxgl-ctrl-group {
  border-top-left-radius: 0px !important;
  border-top-right-radius: 0px !important;
  border-bottom-right-radius: 0px !important;
  border-bottom-left-radius: 0px !important;
  z-index: 10;
}

.mapboxgl-ctrl-attrib.mapboxgl-compact {
  background-color: transparent !important;
}

.mapboxgl-ctrl-attrib-inner a {
  color: #eee !important;
}

.mapboxgl-ctrl.mapboxgl-ctrl-attrib {
  background-color: rgba(0, 0, 0, 0.2) !important;
}

button.mapboxgl-ctrl-attrib-button {
  padding-right: 0px;
}

div.mapboxgl-ctrl-bottom-left {
  bottom: 22px !important;
  left: unset !important;
  right: 10px !important;
  width: 100px;
  z-index: 10;
}

div.mapboxgl-ctrl-bottom-right {
  width: fit-content;
  z-index: 10 !important;
  bottom: 30px !important;
  left: unset !important;
  right: 120px !important;
  z-index: 10;
  font-family: "Vaud Display", Arial, sans-serif;
  display: flex;
  align-items: center;
}

@media (min-width: 2000px) {
  div.mapboxgl-ctrl-bottom-left {
    bottom: 36px !important;
  }

  div.mapboxgl-ctrl-bottom-right {
    bottom: 43px !important;
  }
}

@media (max-width: 1000px) {
  div.mapboxgl-ctrl-bottom-left {
    bottom: 20px !important;
    left: unset !important;
    right: 10px !important;
    width: 100px;
  }

  div.mapboxgl-ctrl-bottom-right {
    width: fit-content;
    z-index: 10 !important;
    bottom: 28px !important;
    left: unset !important;
    right: 110px !important;
  }
}

@media (max-width: 850px) {
  div.mapboxgl-ctrl-bottom-left {
    left: 5px !important;
    width: auto;
  }

  .community-version div.mapboxgl-ctrl-bottom-left {
    bottom: 25px !important;
  }

  .public-version div.mapboxgl-ctrl-bottom-left {
    bottom: 2px !important;
  }

  div.mapboxgl-ctrl-bottom-right {
    width: fit-content;
    z-index: 10 !important;

    left: unset !important;
    right: 3px !important;
  }

  .community-version div.mapboxgl-ctrl-bottom-right {
    bottom: 23px !important;
  }

  .public-version div.mapboxgl-ctrl-bottom-right {
    bottom: 0px !important;
  }
}

.showing {
  color: black;
}

.not-showing {
  color: transparent;
}

.always-show {
  color: black;
}

.showing.overlap {
  color: transparent;
}

.showing.no-overlap {
  color: black;
}
