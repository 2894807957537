@import "../../style/variables";

.map {
  width: 100%;
  position: relative;
  box-sizing: border-box;
}

.mapMarkerWrapper {
  position: absolute;
  height: 10px;
  width: 10px;
  cursor: pointer;
}

.nameWrapper {
  position: absolute;
  z-index: 2;
}

.nameWrapper.hideLabel {
  display: none;
}

.mapMarkerWrapper.active {
  height: 12px;
  width: 12px;
}

.activeLabel {
  font-size: 22px;
  // font-size: 400;
}

.inactiveLabel {
  font-size: 18px;
}

.mapMarkerWrapper.inactive {
  height: 10px;
  width: 10px;
  // Do show all dots on mobile
  // @include media-mobile {
  //   display: none;
  // }
}

.curveBlueMapBase {
  z-index: 2;
  position: absolute;
  left: 0px;
  bottom: -1px;
  width: 100%;
  height: auto;

  @include media-mobile {
    display: none;
  }
}

.curveOrangeMapTop {
  z-index: 2;
  position: absolute;
  left: 0px;
  top: -1px;
  width: 100%;
  height: auto;

  @include media-mobile {
    display: none;
  }
}

.zoomToAreaContainer {
  position: absolute;
  top: 45px;
  right: 10%;
  z-index: 5;
  width: 286px;
  color: $ilf-grey;
  font-weight: 500;

  @include media-mobile {
    display: none;
  }
}

.communityWrapper {
  text-align: center;
  font-family: "Gaegu", Arial, sans-serif;
  font-weight: 700;
}

.communityName {
  font-family: "Gaegu", Arial, sans-serif;
  font-weight: 700;
  line-height: 0.5;
  color: inherit;
}

.communityName.activeLabel {
  font-weight: 700;
}

.mapMsg {
  position: absolute;
  color: $color-black;
  background-color: rgba(255, 255, 255, 0.8);
  padding: 1px 8px;
  z-index: 10;
  @include media-mobile {
    display: none;
  }
  a {
    border-bottom: 1px solid $color-black;
  }
}

// Basically copying the style of Mapbox's mapboxgl-ctrl-bottom-right
.copyrightMsg {
  position: absolute;
  color: rgba(255, 255, 255, 0.9);
  left: 10%;
  bottom: 28px;
  font-size: 12px;
  font-family: "Vaud Display", Arial, sans-serif;
  font-weight: 400;
  background-color: rgba(0, 0, 0, 0.2);
  padding: 0 5px;
  line-height: 20px;
  font-style: normal;
  font-variant-ligatures: normal;
  font-variant-caps: normal;
  font-variant-numeric: normal;
  font-variant-east-asian: normal;
  appearance: auto;
  text-rendering: auto;
  letter-spacing: normal;
  word-spacing: normal;
  text-transform: none;
  text-indent: 0px;
  text-shadow: none;
  z-index: 10;

  @include media-mobile {
    display: none;
  }
}

.mapMsg.contactMsg {
  bottom: 28px;
  left: 10%;
  font-weight: 500;
  font-size: 12px;
  @include media-tablet {
    bottom: 28px;
    font-weight: 500;
    font-size: 12px;
    left: 4%;
  }
  // Full width at the bottom of the map
  @include media-mobile {
    padding-left: 10px;
    display: block;
    width: 100%;
    left: 0px;
    right: 0px;
    bottom: 0px;
  }
}

.numCommunitiesMsg {
  top: 45px;
  left: 10%;
  font-weight: 500;
  font-size: 14px;
  @include media-mobile {
    font-size: 16px;
  }
}

.zoomToAusBtn {
  position: absolute;
  top: 16px;
  right: 16px;
  height: 40px;
  width: 40px;
  background-color: white;
  box-shadow: 0 0 2px rgba(0, 0, 0, 0.9);
  border-radius: 1px;
  z-index: 10;
}
