@import "../../style/variables";

.navigationControl {
  width: 31px;
  position: absolute;
  top: 90px;
  left: 10%;
  z-index: 10;

  @include media-mobile {
    left: 16px;
    top: 16px;
  }
}
